import React from "react";

interface IProps {
    url: string,
    click?: Function,
    width?:Number,
    style?: object,
}

const Pictogram: React.FC<IProps> = (props) => {
    const {url, width, click, style } = props;
    return (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
            src={`${url}`}
            width={`${width ? width : "40"}px`}
            style={{...{padding:15, borderRadius:10, marginLeft:7.5, marginRight:7.5}, ...style} }
            onClick={click ? () =>click() : ()=>{}}
        />
    );
};

export default Pictogram;