import React, { useRef } from 'react';
import { Section, Button, Grid, Avatar, Row, Col, Timeline, Pictogram, Wave, ImageHover, ImageModel, Message, MessageAnimation, ProjectCard } from "./components/components"
//import Section from "./components/section/index";
import "./app.scss"

const scroll = (ref: any) => window.scrollTo(0, ref.current.offsetTop)

const lang: string[] = [
  "js", "csharp", "css", "html", "html5", "java", "php", "python", "sass", "sql", "typescript", "kotlin"
];
const frameworks: string[] = [
  "antd", "hibernate", "materialui", "processing", "react", "react_native", "spring",
];

const programs: string[] = [
  "autocad", "git", "intelij", "office", "photoshop", "rhinoceros", "vscode", "elasticbeanstalk", "circleci", "auth0"
];


function App() {

  const refAbout = useRef(1);
  const refExperience = useRef(2);
  const refCV = useRef(3);
  const refProjects = useRef(4);
  const refContanct = useRef(5);

  return (
    <div>
      <Section>
        <Row center middle style={{ minHeight: "102vh" }}>
          <div style={{ width: 200, padding: 15, paddingTop: 40 }}>
            <Avatar
              url={require("./images/avatar_2.jpg")}
              style={{ width: "100%" }}
            />
          </div>
          <div
            className="banner-ItemsHolder"
            style={{ width: 320, padding: 15 }}
          >
            <h1 className="titleName">Gavin den Hollander</h1>
            <Button onClick={() => scroll(refAbout)} color="off-white">
              Over Mij
            </Button>
            <Button
              onClick={() =>
                window.open("https://github.com/GavinDH", "_blank")
              }
              color="dark"
            >
              GitHub
            </Button>
            <Button onClick={() => scroll(refCV)}>Curriculum vitae</Button>
            <Button color="off-white" onClick={() => scroll(refExperience)}>
              Ervaring
            </Button>
            <Button color="dark" onClick={() => scroll(refProjects)}>
              Projecten
            </Button>
            <Button onClick={() => scroll(refContanct)}>Contact</Button>
          </div>
        </Row>
      </Section>
      <Section
        type="off-white"
        style={{ minHeight: '600px', paddingTop: 50, paddingBottom: 100 }}
        refProp={refAbout}
      >
        <Row center>
          <Col size={8} scale="xl">
            <h1>Over Mij</h1>
            <MessageAnimation>
              <Message side="left" image={require("./images/avatar_2.jpg")} delay={100}>
                Hallo, Mijn naam is Gavin den Hollander. Leuk dat je kijkt op mijn Portfolio!< br />Graag zou ik wat over mijzelf vertellen.
              </Message>

              <Message side="left" image={require("./images/avatar_2.jpg")} delay={2000} typingDelay={300}>
                Vanaf kinds af aan ben ik altijd nieuwsgierig en
                leergierig geweest. Hierdoor wilde ik altijd weten hoe iets werkt en
                hoe dingen gemaakt worden. Dit geldt ook voor websites en met veel
                zoeken en proberen heb ik op mijn 14de mijn eerste website gemaakt.
                Hij was niet mooi of goed geschreven maar wat was ik trots.
                Omdat ik programmeren voornamelijk als een hobby zag ben ik op mijn 17de de
                studie Scheeps- & Jachtbouw gaan volgen op het STC in Rotterdam.
                Eenmaal in mijn tweede jaar ben ik erachter gekomen dat dit toch
                niet mijn ding was. Desalniettemin, heb ik ervoor gekozen de opleiding
                af te maken zodat ik mijn droom, HBO-informatica studeren, kon realiseren
              </Message>

              <Message side="left" image={require("./images/avatar_2.jpg")} delay={4500} typingDelay={2500}>
                Ondertussen ben ik al weer 24 jaar en heb ik net mijn scriptie afgerond voor de opleiding Informatica aan de hogeschool rotterdam.
                Ik heb veel geleerd in de 4 jaar dat ik de studie heb gevold en de volgende uitdaging staat al klaar!
              </Message>

              <Message side="right" image={require("./images/teqplay.png")} delay={6000} typingDelay={5000}>
                Teqplay, Connecting the dots...
              </Message>
            </MessageAnimation>
          </Col>
        </Row>
      </Section>
      <Wave color="454545" />
      <Section
        type="dark"
        style={{ minHeight: 200, paddingTop: 20, paddingBottom: 60 }}
        refProp={refExperience}
      >
        <Row center>
          <Col size={8} scale="xl" addClass="align-items">
            <h1 className="center no-padding">Ervaring</h1>
            <div>
              {lang.map((i) => (
                <Pictogram url={require(`./images/lang/${i}.png`)} />
              ))}
            </div>
            <div>
              {frameworks.map((i) => (
                <Pictogram url={require(`./images/frameworksOrLibs/${i}.png`)} />
              ))}
            </div>
            <div>
              {programs.map((i) => (
                <Pictogram url={require(`./images/programs/${i}.png`)} />
              ))}
            </div>
          </Col>
        </Row>
      </Section>
      <Section type="purple" style={{ minHeight: 200, paddingTop: 50 }} refProp={refCV}>
        <Row center>
          <Col size={5} scale="xl" addClass="align-items hide-1290">
            <h1 className="left">Curriculum vitae</h1>
            <ImageHover image={require("./images/van_nelle_fabriek.jpeg")}>Afstudeer stage Teqplay</ImageHover>
            <ImageHover image={require("./images/security.jpg")}>Minor Cyber Security</ImageHover>
            <ImageHover image={require("./images/quintor.jpeg")}>Minor Fullstack Java</ImageHover>
            <ImageHover image={require("./images/cofano.jpg")}>3de jaars Stage Cofano software solutions</ImageHover>
            <ImageHover image={require("./images/printing.jpg")}>Eigenaar CT Innovations</ImageHover>
            <ImageHover image={require("./images/hr.jpeg")}>Start HBO Informatica</ImageHover>
          </Col>
          <Col size={3} scale="xl">
            <ImageModel
              src={require("./images/cv.jpg")} imageStyle={{ width: '100%' }} alt="CV" />
          </Col>
        </Row>

      </Section>
      <Wave color="f7f7f7" bgColor="840bc0" />
      <Section type="off-white" refProp={refProjects} style={{ paddingBottom: 60 }}>

        <Row center>

          <Col size={8} scale="xl" >
            <h1 className="center no-padding" style={{ textAlign: 'center', paddingBottom: 40 }}>Projecten</h1>
            <Row center>
              <Col size={4} scale="xl" addClass="align-items project-card-col">
                <ProjectCard image={require("./images/project-d.png")}
                  style={{ borderRadius: '100px 15px 15px 100px' }}
                  imageStyle={{ borderRadius: '100px 15px 0px 0px' }}
                  discriptionStyle={{ borderRadius: '0px 0px 15px 100px' }}>
                  <b>Project D</b>
                  <p>Human detectie in een winkel en omzetten in bruikbare data.</p>
                  <p>Gebruikte technieken:</p>
                  <p> - Python, OpenCV, Yolo v3, Dlib</p>
                  <p> - PHP API</p>
                  <p> - ReactJS, AntD</p>
                </ProjectCard>
              </Col>
              <Col size={4} scale="xl" addClass="align-items  project-card-col">
                <ProjectCard image={require("./images/cloudsourced.jpeg")}>
                  <b>Minor Fullstack Java Quintor</b>
                  <p>React</p>
                  <p> - Typescript</p>
                  <p> - Ant Design</p>
                  <p>Java</p>
                  <p> - Spring Boot</p>
                  <p> - PostGres</p>
                  <p> - Hibernate</p>
                  <p> - Lombok</p>
                </ProjectCard>
              </Col>
              <Col size={4} scale="xl" addClass="align-items  project-card-col">
                <ProjectCard image={require("./images/quintor.jpeg")} style={{ borderRadius: '15px 100px 100px 15px' }} imageStyle={{ borderRadius: '15px 100px 0px 0px' }} discriptionStyle={{ borderRadius: '0px 0px 100px 15px' }}>
                  <b>Portfolio</b>
                  <p>Dit portfolio is ook een van mijn laatste projecten.</p>
                  <p>Voor dit project heb ik alleen gebruik gemaakt van typescript, React en Sass</p>
                  <p>Gebruikte technieken:</p>
                  <p>React</p>
                  <p> - Typescript</p>
                  <p> - scss</p>
                </ProjectCard>
              </Col>
            </Row>
          </Col>



        </Row>

      </Section>
      <Section type="dark" refProp={refContanct} style={{ paddingBottom: 60, paddingTop: 60 }}>
        <Row center>
          <Col size={8} scale="xl" >
            <Row center>
              <Col size={3} scale="md" addClass="align-items project-card-col">
                <Pictogram url={require(`./images/icon/phone.png`)} width={30} style={{ borderRadius: '50%', borderColor: 'white', borderStyle: 'solid', borderWidth: 2 }} />
                <h4>Telefoon nummer</h4>
                <a href="tel:+31651969839" >+31651969839</a>
              </Col>
              <Col size={3} scale="md" addClass="align-items project-card-col">
                <Pictogram url={require(`./images/icon/whatsapp.png`)} width={30} style={{ borderRadius: '50%', borderColor: 'white', borderStyle: 'solid', borderWidth: 2 }} />
                <h4>Hey there! I am using whatsapp.</h4>
                <a href="https://wa.me/0031651969839">+31651969839</a>
              </Col>
              <Col size={3} scale="md" addClass="align-items project-card-col">
                <Pictogram url={require(`./images/icon/email.png`)} style={{ borderRadius: '50%', borderColor: 'white', borderStyle: 'solid', borderWidth: 2 }} width={30} />
                <h4>Stuur me een E-mail</h4>
                <a href="mailto:gavindhollander@gmail.com" >gavindhollander@gmail.com</a>
              </Col>
              <Col size={3} scale="md" addClass="align-items project-card-col">
                <Pictogram url={require(`./images/icon/linkedin.png`)} style={{ borderRadius: '50%', borderColor: 'white', borderStyle: 'solid', borderWidth: 2 }} width={30} />
                <h4>Linkedin?</h4>
                <a href="#" onClick={() => window.open("https://www.linkedin.com/in/gavin-den-hollander-06a96214a", "_blank")}>@GavindenHollander</a>
              </Col>
            </Row>
          </Col>


        </Row>
      </Section>
    </div>
  );
}

export default App;
