import React, {useState} from "react";
import "./style.scss";

import Avatar from "../avatar"

interface IProps {
    image: string,

    style?: object,
    children?:any,
    imageStyle?:object,
    discriptionStyle?:object,
}

const ProjectCard: React.FC<IProps> = (props) => {
    const { children, style, image, imageStyle, discriptionStyle} = props;

    const [showDiscription, setShowDiscription] = useState<Boolean>(false);

    return (
        <div style={{paddingBottom:20, height:"calc(100% - 20px)"}}>
        <div
            className={`project-card`}
            style={style}
        >
            <div className={`image `}>
                <Avatar url={image} squere={true} style={imageStyle}/>

            </div>

            <div className="discription" style={discriptionStyle}>
                {children}
            </div>
            
        </div>
        </div>
    );
};

export default ProjectCard;