import React, {useState, useEffect} from "react";

import useIsInViewport from 'use-is-in-viewport'
import Avatar from "../avatar";
import "./style.scss";

interface IProps {

    children:any,
    
}
const MessageAnimation: React.FC<IProps> = (props) => {
    const {children } = props;

    const [isInViewport, targetRef] = useIsInViewport()


    return (
        <div style={{width:'100%'}} ref={targetRef}>
            {isInViewport ? children : <></>}
        </div>
    );
        
        // eslint-disable-next-line jsx-a11y/alt-text

};

export default MessageAnimation;