import React, {useState, useEffect} from "react";

import useIsInViewport from 'use-is-in-viewport'
import Avatar from "../avatar";
import "./style.scss";

interface IProps {
    side: "left" | "right",
    image: string, 
    delay?: number,
    typingDelay?: number,
    children:any,
    
}
const Message: React.FC<IProps> = (props) => {
    const {side, image, delay, children, typingDelay } = props;

    const [show, setShow] = useState(false);
    const [showTyping, setShowTyping] = useState(false);

    useEffect(() => {
            setTimeout(() => {
                    setShow(true);
                    setShowTyping(false);
                }, delay || 0);
            setTimeout(() => {
                    setShowTyping(true);
                }, typingDelay || 0);
        
    },[])


    return (

        <>
        {showTyping ? 
        (
            <div className="typing-indicator" style={{float:side}}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        ) : (
        <div className={`message-div ${side} ${show ? 'visible' : 'hidden'}`}>
            <div className="avatar-div">
                <Avatar url={image} />
            </div>
            <div className="message">
                <p>{children}</p>
            </div>
        </div>)}
        </>
    );
        
        // eslint-disable-next-line jsx-a11y/alt-text

};

export default Message;